<template>
  <v-container
    class="payment-type payment-type-redirect payment-type-argentea-satispay"
  >
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div class="payment-type-description" v-html="paymentType.descr"></div>
    <PrebilledAmount
      :mode="mode"
      :deliveryServiceId="deliveryServiceId"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />

    <div
      v-if="$te('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      v-html="$t('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      class="redirect-text"
    ></div>

    <ResponseMessage :response="response" />
    <br />
    <v-row class="d-flex justify-end">
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-btn
          v-if="options.back.show && mode != 'order' && !options.hidePrevButton"
          :color="options.back.color"
          :outlined="options.back.outline"
          :icon="options.back.icon"
          :link="options.back.link"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          :large="options.back.large"
          :class="options.back.class"
        >
          {{ options.back.label }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :icon="options.confirm.icon"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import ResponseMessage from "@/components/ResponseMessage";
import { payment, handlePayResponseKo } from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";

import { mapActions } from "vuex";

export default {
  name: "PaymentTypeArgenteaSatispay",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      response: {}
    };
  },
  components: { PrebilledAmount, ResponseMessage },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      updateCurrentOrderId: "app/updateCurrentOrderId"
    }),
    async buy() {
      let vm = this;
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            userPaymenthAuthId: this.selectedPaymentAuthId,
            paymentData: paymentData
          });
          if (cart) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(cart.order.orderId);
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId,
            this.selectedPaymentAuthId,
            paymentData
          );
          if (response) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      let vm = this;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            paymentData: paymentData
          });
          if (cart) {
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId,
            paymentData
          );
          if (response) {
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "post", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        // this.$router.push("/cart/payment-completed/" + orderId);
        this.$router.push({
          name: "PaymentCompleted",
          params: {
            orderId: orderId
          }
        });
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;
      handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    },
    goBack() {
      // this.$router.push("/checkout");
      this.$router.push({ name: "Checkout" });
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    toggleRememberCard(value) {
      this.rememberNewCard = value;
    }
  }
};
</script>
